import React from "react";
import Layout from "../comps/reusable/Layout";
import { Link, graphql } from "gatsby";
import Seo from "../comps/reusable/Seo";
import { useTranslation } from "react-i18next";
import { Linkedin } from "styled-icons/bootstrap";
import {
  CompaniesSection,
  CostumerSection,
  FaqsContainer,
  Hero,
  InfoServicesSection,
  MainInfoSection,
  TalentSpecialistSection,
  ContactSection,
} from "../styles/pages/executive-search";
import Carousel from "../comps/reusable/Carousel";
import stars from "../images/executive-search/stars.png";
import star from "../images/executive-search/star.svg";
import userStar from "../images/executive-search/user-stars.svg";
import clock from "../images/executive-search/clock.svg";
import CarouselTestimonials from "../comps/reusable/CarouselTestimonials";
import figures from "../images/home/figures.png";
import { TeamSection } from "../styles/pages/about-us";
import Faqs from "../comps/reusable/Faqs";
import contactFigure1 from "../images/home/contact-figure-1.png";
import contactFigure2 from "../images/home/contact-figure-2.png";

const oxxo =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2F135.png?alt=media&token=4a4a1547-d32a-4231-bc60-8e45385304b1";
const banregio =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2F136.png?alt=media&token=b0f7e419-98ee-4c81-9c1c-53ac684e893a";
const femsa =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2F137.png?alt=media&token=1478f68d-9941-4d41-af72-b6abc0940d58";
const abin =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2F138.png?alt=media&token=d300ed2e-1cdf-4502-8cf2-1ed6582e15b8";
const abi5 =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2F139.png?alt=media&token=0db0ecf7-9616-4725-bb44-bb996b55fbbf";
const abi6 =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2F140.png?alt=media&token=6f48e09b-cffe-46a9-a156-75b56588a264";
const abi7 =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2F141.png?alt=media&token=44062897-81d0-44c3-b15b-88e00402d38a";

const talentSpecialits =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fspecialits.png?alt=media&token=75cd8d0c-8a15-4114-a955-06f70210008d";
const talentPlatform =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Ftalent-platform.png?alt=media&token=c0aef133-37ec-4aa4-bd5f-621397f6145f";
const forBussiness =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fplatform.png?alt=media&token=60ffb037-cd2d-40fc-b0b1-53462f16983a";

const ExecutiveSearch = () => {
  const { t } = useTranslation("executive-search");

  const team = [
    {
      name: "Coni Riveros",
      position: "People & Culture Director en Dentalia",
      oldPosition: "Ex Managing Partner en NUMA",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2FConi%20Riveros.jpeg?alt=media&token=686d72b7-cc33-4904-be87-57a3ab9ec6b7",
      ulrLinkendIn: "https://www.linkedin.com/in/coniriveros/",
    },
    {
      name: "David Hoffs",
      position: "Chief Finance Officer en Fondeadora Inc",
      oldPosition: "Ex Chief Strategy Officer en Quiero Casa",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2FDavid%20Hoffs.jpeg?alt=media&token=2dbb82d7-27a1-4a21-b561-b7139ced2cdb",
      ulrLinkendIn: "https://www.linkedin.com/in/david-hoffs-8b281531/",
    },
    {
      name: "Daphne S. Leger",
      position: "CEO & Founder en Atrevidea",
      oldPosition: "Ex Innovation Manager en Cinepolis",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2FDaphne%20Leger.jpeg?alt=media&token=7c0b209e-017f-4421-92e9-42997071d33f",
      ulrLinkendIn: "https://www.linkedin.com/in/daphnesleger/",
    },
    {
      name: "Mackey Vazquez",
      position: "CTO, founder en Code Report",
      oldPosition: "Ex Engineering Manager en Moonshot Partners",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2FMackey%20Vazquez.jpeg?alt=media&token=9c71378d-6d1e-44dc-99e9-6ff960f39333",
      ulrLinkendIn: "https://www.linkedin.com/in/mackeyvazquez/",
    },
    {
      name: "Fabiola Bórquez",
      position: "Senior Product Manager en Scotiabank",
      oldPosition: "Ex Head of Product en Runa",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2FWhatsApp%20Image%202023-07-26%20at%2011.29.49.jpeg?alt=media&token=8e26f25f-3603-40af-a6a4-1df41057ed43",
      ulrLinkendIn: "https://www.linkedin.com/in/fabiborquez/",
    },
    {
      name: "Álvaro Ruiz",
      position: "Head of Sales de Buk",
      oldPosition: "Ex Country Manager en Talent.com",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2FA%CC%81lvaro%20Ruiz%20(1).jpg?alt=media&token=8409f3bc-e0e9-47b2-9c65-174d97c733c7",
      ulrLinkendIn: "https://www.linkedin.com/in/alvaroruizpu/",
    },
    {
      name: "Ana Zamacona",
      position: "Head of Brand & Communications en Fondeadora",
      oldPosition: "Ex Associate Creative Director en TERAN/TBWA",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2FAna%20Zamacona%20(1).jpeg?alt=media&token=a35100b6-7d8e-4243-904d-a8fe5fb088b5",
      ulrLinkendIn: "https://www.linkedin.com/in/anazam/",
    },
    {
      name: "Jorge Fernández",
      position: "Fintech specialist",
      oldPosition: "Ex General Manager Débito en Fondeadora",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2FJorge%20Ferna%CC%81ndez.jpeg?alt=media&token=3b3faa5f-e6cf-4378-a8d4-2ad57a9c4305",
      ulrLinkendIn: "https://www.linkedin.com/in/jorge-fern%C3%A1ndez-braun/",
    },
    {
      name: "Stella Yoh",
      position: "Venture Capital & Principal en Orbit Startups",
      oldPosition: "Ex Senior Strategy Consultant en IFC",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2FStella%20Yoh.jpeg?alt=media&token=b4b46681-39ca-4217-b5d9-448f67b68946",
      ulrLinkendIn: "https://www.linkedin.com/in/stella-yoh/",
    },
    {
      name: "Michael Collemiche",
      position: "Chief Revenue Officer en Algorithia",
      oldPosition: "Ex Executive Director Data & Analytics en Belcorp",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2Fimg_michael.jpeg?alt=media&token=431aeba0-be9d-42dc-897a-5ecf535e3ea0",
      ulrLinkendIn: "https://www.linkedin.com/in/michael-collemiche/",
    },
    {
      name: "Marcelo Galván de la Garza",
      position: "Head of Business Development & Ops en Digital@FEMSA",
      oldPosition: "Ex Co-founder de Boitas.com",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2Fgalvan.jpeg?alt=media&token=9adfa0c3-1530-483b-bc88-ba86f23e7df5",
      ulrLinkendIn: "https://www.linkedin.com/in/marcelogalvandelagarza12/",
    },
    {
      name: "Jorge Arturo Fernandez",
      position: "Business & Health Director en Mellow Consulting",
      oldPosition: "Ex Medical Director en Carecheck",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2FJorge%20Arturo%20Fernandez.jpeg?alt=media&token=415d86ff-8bee-4926-a990-45667f2ff93f",
      ulrLinkendIn: "https://www.linkedin.com/in/jorgefernandezmd/",
    },
    {
      name: "Abdala Pineda",
      position: "Chief of growth en Spin by OXXO",
      oldPosition: "Ex Chief Gorwth Officer en Fondeadora",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2FAbdala.jpeg?alt=media&token=86049ee6-1e26-40d7-80df-bd77020ad758",
      ulrLinkendIn: "https://www.linkedin.com/in/abdalapineda/",
    },
    {
      name: "Everton Viana",
      position: "Co-Founder & COO Cargamos",
      oldPosition: "Ex City Manager Rappi ",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2Feverton.jpeg?alt=media&token=f016795f-472d-42f1-9f61-c6046918ed71",
      ulrLinkendIn: "https://www.linkedin.com/in/evertonviana/",
    },
    {
      name: "Jedidiah Horne ",
      position: "Head of Engineering- Klym",
      oldPosition: "Ex Uber",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FExecutiveSearch%2Fconsultores%2Fjedidiah.jpeg?alt=media&token=75ef2d81-318b-40fb-a896-10c0dd49e3ae",
      ulrLinkendIn: "https://www.linkedin.com/in/jedhorne/",
    },
  ];

  const faqs = [
    {
      question: t("question1"),
      answer: t("answer1"),
    },
    {
      question: t("question2"),
      answer: t("answer2"),
    },
    {
      question: t("question3"),
      answer: t("answer3"),
    },
  ];
  return (
    <Layout modeHeader="transparent">
      <Seo
        title="¿Buscando agencias de reclutamiento? Encontramos los perfiles más complejos"
        description="Queremos que cumplas tus objetivos de reclutamiento en tiempo récord. Un sourcing inteligente, eficiente y humano."
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FSEO%2FTAG3.png?alt=media&token=1e0186ca-89d1-4e65-9386-e05e7e7c4ce7"
      />
      <Hero>
        <div className="hero-content">
          <article>
            <h1>{t("heroTitle")}</h1>
            <p>{t("heroDesc")}</p>
            <a
              href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("requestInfo")}
            </a>
          </article>
        </div>
      </Hero>
      <CompaniesSection>
        <h2>{t("companySectionTitle")}</h2>
        <div className="companies-section">
          <Carousel
            marque
            logos={[oxxo, banregio, femsa, abin, abi5, abi6, abi7]}
            width="28rem"
          />
        </div>
      </CompaniesSection>
      <TalentSpecialistSection>
        <div className="main-landing-container-row">
          <figure>
            <img src={talentSpecialits} alt="Talent Specialist" />
          </figure>
          <article>
            <h2>{t("talentTitle")}</h2>
            <p>{t("talentDescription")}</p>
            <a
              href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("requestInfo")}
            </a>
          </article>
        </div>
      </TalentSpecialistSection>
      <InfoServicesSection>
        <span className="main-title-services">
          <img src={stars} alt="¿Por qué elegir nuestro servicio?" />
          <h2>{t("infoTitle")}</h2>
        </span>
        <div className="sevices-list">
          <article className="service-item">
            <figure>
              <img src={star} alt="stars" />
            </figure>
            <h3>{t("infoItemOneTitle")}</h3>
            <p>{t("infoItemOneDescription")}</p>
          </article>
          <article className="service-item">
            <figure>
              <img src={userStar} alt="stars" />
            </figure>
            <h3>{t("infoItemTwoTitle")}</h3>
            <p>{t("infoItemTwoDescription")}</p>
          </article>
          <article className="service-item">
            <figure>
              <img src={clock} alt="stars" />
            </figure>
            <h3>{t("infoItemThreeTitle")}</h3>
            <p>{t("infoItemThreeDescription")}</p>
          </article>
        </div>
      </InfoServicesSection>
      <MainInfoSection>
        <div className="main-landing-container-row main-info-platform">
          <article>
            <h3>{t("mainFisrtTitle")}</h3>
            <p>{t("mainFisrtDescription")}</p>
          </article>
          <figure>
            <img src={talentPlatform} alt="Platform ATS" />
          </figure>
        </div>
        <div className="main-landing-container-row main-info-bussiness">
          <figure>
            <img src={forBussiness} alt="Platform ATS" />
          </figure>
          <article>
            <h3>{t("mainSecondTitle")}</h3>
            <p>{t("mainSecondDescription")}</p>
            <a
              href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("requestInfo")}
            </a>
          </article>
        </div>
      </MainInfoSection>
      {/* <CostumerSection>
        <div className="main-landing-container-column">
          <h2>
            {t("testimonialTitle")}
            <img src={figures} alt="Ilustración de LaPieza" />
          </h2>
          <CarouselTestimonials />
        </div>
      </CostumerSection> */}
      <TeamSection>
        <div className="main-landing-container-column">
          <h2>{t("Algunos de nuestros expertos")}</h2>
          <h3>{t("Consultores de Executive Search")}</h3>
          <div className="team-list">
            {team.map((item, idx) => (
              <>
                {item.picture && (
                  <article key={`member-item-${idx}`}>
                    <figure>
                      <img src={item.picture} alt="Foto de Pol en LaPieza" />
                    </figure>
                    <div>
                      <a
                        href={item.ulrLinkendIn}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h5>
                          <Linkedin
                            className="icon-linkedin"
                            size={20}
                            color="#0a66c2"
                          />
                          {item.name}
                        </h5>
                      </a>
                      <p>{item.position}</p>
                      <p>{item.oldPosition}</p>
                    </div>
                  </article>
                )}
              </>
            ))}
          </div>
        </div>
      </TeamSection>
      <FaqsContainer>
        <div className="main-landing-container-column">
          <h2>{t("faqsTitle")}</h2>
          <Faqs faqs={faqs} className="faqs" />
        </div>
      </FaqsContainer>
      <ContactSection>
        <div className="main-landing-container-column">
          <div className="title-contact">
            <img
              className="figure-one"
              src={contactFigure1}
              alt="Ilustración de LaPieza"
            />
            <div>
              <h2>{t("contactSectionTitle")}</h2>
            </div>
            <img
              className="figure-two"
              src={contactFigure2}
              alt="Ilustración de LaPieza"
            />
          </div>
          <p>{t("contactSectionDescription")}</p>
          <a
            className="action-button"
            href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("requestInfo")}
          </a>
        </div>
      </ContactSection>
    </Layout>
  );
};

export default ExecutiveSearch;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
